/* eslint-disable react/no-array-index-key */
import React, { useState } from "react"
import BackgroundImage from "../components/background-image"
import Icon from "../../icons"
import { SectionType } from "../../../types"
import { createMarkup } from "../../../../utils/markup-creator"

type SectionProps = {
  section: SectionType
  index: any
  [key: string]: any
}

const TestimonialsSection = ({ section, index, ...props }: SectionProps) => {
  const [stateData, setData] = useState({
    testimonialIndex: 0,
  })

  const changeTestimonial = function (move: number) {
    const newIndex = stateData.testimonialIndex + move
    const correctedNewIndex = newIndex < 0 ? section.testimonials?.length - 1 : newIndex >= section.testimonials.length ? 0 : newIndex

    setData({ ...stateData, testimonialIndex: correctedNewIndex })
  }

  const changeTestimonialTo = function (index: number) {
    setData({ ...stateData, testimonialIndex: index })
  }

  const testimonial = section.testimonials?.length > stateData.testimonialIndex ? section.testimonials[stateData.testimonialIndex] : undefined

  return (
    <div className={`relative w-full`}>
      <BackgroundImage
        url={section.advanced?.backgroundImageLg}
        mobileUrl={section.advanced?.backgroundImageSm}
        height={section.advanced?.backgroundHeight}
        backgroundPosition={section.advanced?.backgroundPosition}
        backgroundColor={section.advanced?.backgroundColor}
        backgroundFit={section.advanced?.backgroundFit}
      />
      <div className="max-w-screen-lg mx-auto sm:px-6 lg:px-0 h-full">
        {section.blurb && <div dangerouslySetInnerHTML={createMarkup(section.blurb)} />}
        <div className="w-full h-full grid grid-cols-1 md:grid-cols-2 md:gap-6">
          <div className={`relative w-full h-full ${section.advanced?.class || ``}`}>
            <div className="h-full mx-4 md:mx-12 flex flex-col content-center">{section.blurb2 && <div dangerouslySetInnerHTML={createMarkup(section.blurb2)} />}</div>
            <div className="clear-both"></div>
          </div>

          <div className={`relative w-full h-full ${section.advanced?.class2 || ``}`}>
            <div className="p-8 mx-2 bg-background border-solid border border-primary-200 rounded-lg shadow-xl flex flex-row flex-wrap items-center">
              <div className="flex-none w-14">
                <img className="h-14 w-14 rounded-full" src={testimonial?.image} alt="" />
              </div>
              <div className="flex-none w-32 ml-4">
                <span className="text-lg font-medium">{testimonial?.name}</span>
                <div className="flex space-x-1 text-sm">{testimonial?.location}</div>
              </div>
              <div className="w-full mt-6 text-base">{testimonial?.testimonial}</div>
            </div>

            <div className="mt-8 flex flex-row flex-nowrap justify-center">
              <a className="mr-8" onClick={() => changeTestimonial(-1)}>
                <Icon className="text-primary" height={22} icon="arrow-left3" />
              </a>
              {section.testimonials.map((testimonial, testimonialIndex) => (
                <a className="mx-2" key={`dot-${testimonialIndex}`} onClick={() => changeTestimonialTo(testimonialIndex)}>
                  <Icon
                    className={`rounded-full ${testimonialIndex === stateData.testimonialIndex ? `text-highlight bg-highlight` : `text-primary bg-primary opacity-20`}`}
                    height={10}
                    icon="minus-circle"
                  />
                </a>
              ))}
              <a className="ml-8" onClick={() => changeTestimonial(1)}>
                <Icon className="text-primary" height={22} icon="arrow-right3" />
              </a>
            </div>

            <div className="clear-both"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialsSection
